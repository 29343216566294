<template>
    <div
        v-if="taxonomy.multi_select === '1' && taxonomy.multi_select_type === 'checkbox'"
        v-for="term in taxonomy.terms"
        :key="term.title"
        class="flex align-items-center">
      <Checkbox
          v-model="filters[index]"
          :inputId="term.title"
          name="taxonomy.name"
          :value="{term: term.title}"
          @change="setFilterTerms(filters[index], taxonomy)"
      />
      <label :for="term.title">{{ term.title }}</label>
    </div>
    <Dropdown
        v-if="taxonomy.multi_select === '0'"
        v-model="filters[index]"
        :options="getSelectInputOptions(taxonomy.terms)"
        optionLabel="term"
        :placeholder="'Select a ' + taxonomy.name"
        class=""
        showClear
        @change="setFilterTerms(filters[index], taxonomy)"
    />
    <MultiSelect
        v-if="taxonomy.multi_select === '1' && taxonomy.multi_select_type === 'dropdown'"
        v-model="filters[index]"
        :options="getSelectInputOptions(taxonomy.terms)"
        optionLabel="term"
        :placeholder="'Select ' + taxonomy.plural_name"
        :maxSelectedLabels="3"
        class=""
        @change="setFilterTerms(filters[index], taxonomy)"
    />
</template>

<script>
import MultiSelect from "primevue/multiselect"
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

export default {
  components: {MultiSelect, Dropdown, Checkbox},
  props:      {
    index:      {
      type:    Number,
      default: 0
    },
    taxonomy: {
      type:    Object,
      default: {}
    },
  },
  data() {
    return {
      filters:             []
    };
  },
  methods: {
    getSelectInputOptions(terms) {
      let options = [];
      for (let term in terms) {
        options.push({
          term: terms[term].title
        })
      }

      return options;
    },
    setFilterTerms(filter, taxonomy) {
      // console.log('filter: ');
      // console.log(filter);
      let taxPluralName = taxonomy.plural_name;
      let taxonomyIsMultiSelect = Boolean(Number(taxonomy.multi_select));
      // console.log(taxonomyIsMultiSelect);
      // console.log(taxonomy);
      this.$parent.$parent.taxonomyFilterTerms[taxPluralName] = [];
      if (filter) {
        if (taxonomyIsMultiSelect) {
          for (let option in filter) {
            // console.log('Filter term ' + filter[option].term + ' added to ' + taxPluralName + ' taxonomy filter list');
            this.$parent.$parent.taxonomyFilterTerms[taxPluralName].push(filter[option].term);
          }
        } else {
          console.log(filter);
          // console.log('Filter term ' + filter.term + ' replaced ' + taxPluralName + ' taxonomy filter list');
          this.$parent.$parent.taxonomyFilterTerms[taxPluralName].push(filter.term);
        }
      }
      // console.log('Active term filters for: ' + taxPluralName);
      // console.log(this.$parent.taxonomyFilterTerms[taxPluralName]);

      this.$parent.filterItems();
    }
  }
}
</script>