<template>
  <section class="media-doctor-wrapper">
    <h1>Media Doctor</h1>
    <section class="section-body">
      <div class="action load">
        <FileUpload mode="basic"
                    chooseLabel="Upload Crawl Data CSV"
                    name="external_crawl_data[]"
                    url="/wp-admin/admin-ajax.php?action=upload_external_crawl_data_file"
                    accept="text/csv"
                    :maxFileSize="1000000"
                    @upload="onUpload" :auto="true"
                    @before-upload="isBusyFetchingCrawlData = true"
                    :disabled="isBusyFetchingCrawlData" />
        <Button label="Help" icon="pi pi-external-link" @click="showCSVHelp = true" severity="help" />
        <Dialog v-model:visible="showCSVHelp" modal header="Crawl Data CSV Preparation" :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
          <CrawlDataHelp></CrawlDataHelp>
        </Dialog>
        <p class="update-text" v-if="crawlData.length">
          You have uploaded {{ crawlData.length }} image filenames from an external crawl. See console for more details.
        </p>
      </div>
      <div v-if="isCrawlDataSet" class="action find">
        <Button label="Find Orphans"
                @click="findOrphans"
                :disabled="isBusyFindingOrphans" />

        <p class="update-text" v-if="hasProcessedCrawlData && hasOrphans">
          {{ attachmentData.ml_orphaned_attachments.length }} orphans found. See console for more details.
        </p>
        <p class="update-text" v-if="hasProcessedCrawlData && hasDuplicates">
          {{ attachmentData.crawled_duplicate_attachments.length }} duplicate attachments found. See console for more
          details.
        </p>
        <p class="update-text" v-if="hasProcessedCrawlData && hasUnmatched">
          {{ attachmentData.crawled_unmatched_attachments.length }} unmatched attachments. See console for more details.
        </p>
        <p class="update-text" v-if="hasProcessedCrawlData && !hasOrphans">
          No orphans found.
        </p>
      </div>
      <div v-if="hasProcessedCrawlData && hasOrphans" class="action destroy">
        <Button label="Destroy Orphans"
                @click="destroyOrphans"
                :disabled="isBusyDestroyingOrphans" />
        <InputNumber
            v-model="destroyChunk"
            showButtons
            :min="1"
            :max="100" />
        <Button v-if="isBusyDestroyingOrphans" label="Stop Destruction"
                @click="stopDestroyingOrphans = true" />

        <div v-if="elapsedTime" class="progress">
          <ProgressBar ProgressBar :value="destroyOrphansProgress"></ProgressBar>
          <div class="elapsed-time">
            <span><strong>Elapsed Time: </strong>{{ elapsedTime / 10 }}s</span>
          </div>
          <div class="total-processed">
            <span><strong>Orphans Processed: </strong>{{
                orphansProcessed
                                                      }}/{{ attachmentData.ml_orphaned_attachments.length }}</span>
          </div>
        </div>
        <!--        <ul v-if="deletedOrphanSets.length">-->
        <!--          <li v-for="deletedOrphans in deletedOrphanSets">-->
        <!--            {{ deletedOrphans.length }} orphans destroyed. See console for more details.-->
        <!--          </li>-->
        <!--        </ul>-->
        <ul v-if="failedDeletedOrphansSets.length">
          <li v-for="failedDeletedOrphans in failedDeletedOrphansSets" class="warning">
            {{ failedDeletedOrphans.length }} orphans failed deletion. See console for more details.
          </li>
        </ul>
      </div>
    </section>

  </section>
</template>

<script>

import CrawlDataHelp from './CrawlDataHelp';
import ProgressBar from 'primevue/progressbar';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import axios from "axios";
import qs from "qs";

export default {
  components: {FileUpload, Button, ProgressBar, InputNumber, Dialog, CrawlDataHelp},
  props:      {},

  data() {
    return {
      showCSVHelp:              false,
      orphansProcessed:         0,
      destroyChunk:             50,
      elapsedTime:              0,
      stopDestroyingOrphans:    false,
      isBusyFetchingCrawlData:  false,
      isBusyFindingOrphans:     false,
      isBusyDestroyingOrphans:  false,
      isCrawlDataSet:           false,
      hasProcessedCrawlData:    false,
      hasOrphans:               false,
      hasDuplicates:            false,
      hasUnmatched:             false,
      crawlData:                [],
      attachmentData:           {
        ml_orphaned_attachments:       [],
        crawled_unmatched_attachments: [],
        crawled_duplicate_attachments: [],
      },
      deletedOrphanSets:        [],
      failedDeletedOrphansSets: [],
      destroyOrphansProgress:   0
    };
  },
  mounted() {
    // console.log('MediaDoctor component mounted');
  },
  computed: {},
  methods:  {
    timer() {
      if (this.isBusyDestroyingOrphans) {
        setTimeout(() => {
          this.elapsedTime++
          this.timer();
        }, 100)
      }
    },
    async onUpload(e) {
      console.log('External crawl data uploaded.  Fetching data array...');
      let data = {
        action: "get_external_crawl_data_file_array",
        data:   {
          test: "testing 123"
        },
      };
      await axios
          .post(
              window.lhp_vc_data.ajax_url,
              qs.stringify(data)
          )
          .then((response) => {
            this.crawlData = response.data.crawl_data;
            console.log(this.crawlData);
            console.log('---')
            if (this.crawlData.length) {
              this.isCrawlDataSet = true;
            }

          })
          .catch((error) => {
            console.log(error)
          });
      this.isBusyFetchingCrawlData = false;
    },
    async findOrphans() {
      this.isBusyFindingOrphans = true;
      console.log('Finding orphaned attachments in Media Library... ');

      let data = {
        action: "find_orphaned_media_library_attachments",
        data:   {
          test: "testing 345"
        },
      };
      await axios
          .post(
              window.lhp_vc_data.ajax_url,
              qs.stringify(data)
          )
          .then((response) => {
            this.attachmentData = response.data.attachment_data;
            console.log(this.attachmentData);
            console.log('---')
            this.hasProcessedCrawlData = true;
            if (this.attachmentData.ml_orphaned_attachments.length) {
              this.hasOrphans = true;
            }
            if (this.attachmentData.crawled_duplicate_attachments.length) {
              this.hasDuplicates = true;
            }
            if (this.attachmentData.crawled_unmatched_attachments.length) {
              this.hasUnmatched = true;
            }

          })
          .catch((error) => {
            console.log(error)
          });
      this.isBusyFindingOrphans = false;
    },
    async destroyOrphans() {
      this.isBusyDestroyingOrphans = true;
      this.timer();
      console.log('Destroying Orphans... ');

      const chunks = Math.ceil(this.attachmentData.ml_orphaned_attachments.length / this.destroyChunk);

      for (let i = 0; i < chunks; i++) {
        if (this.stopDestroyingOrphans) {
          break;
        }
        let sliceStart = i * this.destroyChunk;
        let sliceEnd = i ? sliceStart + (this.destroyChunk) : this.destroyChunk;
        let orphans = this.attachmentData.ml_orphaned_attachments.slice(sliceStart, sliceEnd);

        let data = {
          action: "destroy_orphan_media_library_attachments",
          data:   {
            orphans: orphans
          },
        };
        await axios
            .post(
                window.lhp_vc_data.ajax_url,
                qs.stringify(data)
            )
            .then((response) => {
              console.log(response.data)
              this.orphansProcessed = this.orphansProcessed + response.data.success.length + response.data.failure.length;
              this.destroyOrphansProgress = Math.ceil((this.orphansProcessed / this.attachmentData.ml_orphaned_attachments.length) * 100);
              if (response.data.success.length) {
                this.deletedOrphanSets[i] = response.data.success;
              }
              if (response.data.failure.length) {
                this.failedDeletedOrphansSets[i] = response.data.failure;
              }
            })
            .catch((error) => {
              console.log(error)
            });
      }
      this.isBusyDestroyingOrphans = false;
    }
  }
}
</script>