<template>
  <section class="compare-media">
    <div class="action">
      <FileUpload mode="basic"
                  chooseLabel="Upload Media Data CSV"
                  name="media_data[]"
                  url="/wp-admin/admin-ajax.php?action=upload_media_comparison_file"
                  accept="text/csv"
                  :maxFileSize="1000000"
                  @before-upload="isBusyFetchingData = true"
                  @upload="onUpload" :auto="true"
                  :disabled="isBusyFetchingData"/>
    </div>
    <div class="action">
    <Button
        v-if="sfMedia.length && dtMedia.length"
        label="Compare"
        @click="compareMedia" />
    </div>
    <div v-if="uniqueMedia.length" class="results">
      <DataTable
          :value="uniqueMedia"
          size="small"
          stripedRows
          paginator :rows="20">
        <Column field="url" header="URL" sortable></Column>
      </DataTable>
    </div>
  </section>
</template>

<script>

import ProgressBar from 'primevue/progressbar';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import axios from "axios";
import qs from "qs";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  components: {FileUpload, Button, ProgressBar,DataTable, Column},
  props:      {},

  data() {
    return {
      uniqueMedia:          [],
      mediaData:          [],
      isBusyFetchingData: false
    };
  },
  mounted() {
    console.log('CompareMedia component mounted');
  },
  computed: {
    sfMedia() {
      return this.mediaData.map((row) => {
        return row.screamingfrog;
      });
    },
    dtMedia() {
      return this.mediaData.map((row) => {
        return row.devtools;
      });
    }
  },
  methods:  {
    async onUpload(e) {
      console.log('Media data uploaded.  Fetching data array...');
      let data = {
        action: "get_media_data_file_array",
        data:   {
          test: "testing 123"
        },
      };
      await axios
          .post(
              window.lhp_vc_data.ajax_url,
              qs.stringify(data)
          )
          .then((response) => {
            this.mediaData = response.data.media_data;
            console.log(this.mediaData);
            console.log('---')

          })
          .catch((error) => {
            console.log(error)
          });
      this.isBusyFetchingData = false;
    },
    compareMedia() {
      // console.log(this.sfMedia);
      // console.log(this.dtMedia);

      this.sfMedia.map((media) => {
        let isUnique = true;
        for(let i = 0; i < this.dtMedia.length; i++ ) {
          if(this.dtMedia[i] === media) {
            isUnique = false;
          }
        }
        if(isUnique) {
          this.uniqueMedia.push({url: media});
        }
      })

      console.log(this.uniqueMedia);
    }
  }
}
</script>