<template>
  <section class="crawl-controls">
    <Button label="Start Crawl"
            @click="$parent.startCrawl"
            :disabled="$parent.isBusy || $parent.isCrawling || $parent.crawlPaused || $parent.displayFinalResults" />
    <Button :label="$parent.crawlPaused ? 'Unpause' : 'Pause'"
            @click="$parent.toggleCrawl"
            :disabled="$parent.isBusy || (!$parent.isCrawling && !$parent.crawlPaused) || $parent.displayFinalResults" />
    <Button label="Reset"
            @click="$parent.resetCrawl"
            :disabled="$parent.isBusy" />
    <InputNumber
        v-model="$parent.maxThreads"
        showButtons
        :min="1"
        :max="20"
        :disabled="$parent.displayFinalResults" />

    <Dropdown v-if="previousCrawls.length" v-model="previousCrawl" :options="previousCrawls" optionLabel="timestamp"
              optionValue="filename"
              placeholder="Load A Previous Crawl" @change="loadPreviousCrawlData" />
  </section>
</template>

<script>
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import axios from "axios";

export default {
  components: {Button, InputNumber, Dropdown},
  props:      {
    previousCrawls: {
      type:    Array,
      default: []
    },
  },
  data() {
    return {
      previousCrawl: false,
    };
  },
  mounted() {
    // console.log('CrawlControls component mounted');
  },
  computed: {},
  methods:  {
    loadPreviousCrawlData() {
      console.log(this.previousCrawl);

      axios.get(this.$parent.origin + "/wp-content/uploads/site-crawler/" + this.previousCrawl)
          .then(response => {
            console.log(response.data);
            // this.previousCrawlData = response.data;
            this.$parent.previousCrawlDataLoaded = true;
            this.$parent.results = response.data;
          }).catch(function (error) {
        console.log(error.response);
      });
    }
  }
}
</script>