<template>
  <div class="cards" :class="[orphanAlignmentClass, 'flex-cols flex-cols-1', flexColClasses]">
    <ItemCard
        v-for="item in items"
        :item="item"
        :cardSettings="cardSettings">
    </ItemCard>
  </div>
</template>

<script>
import ItemCard from "./ItemCard.vue";

export default {
  components:           {ItemCard},
  props:                {
    items: {
      type:    Array,
      default: []
    },
    orphanAlignmentClass: {
      type:    String,
      default: 'justify-content-flex-start'
    },
    cardSettings: {
      type:    Object,
      default: {}
    },
  },
  computed: {
    flexColClasses(){
      let classes = this.cardSettings.breakpoints.map((breakpoint) => {
        return breakpoint.prefix + ":flex-cols-" + breakpoint.value
      });
      return classes.join(" ");
    }
  }
}
</script>