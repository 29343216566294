import CollectionItems from "@/components/CollectionItems/CollectionItems";
import DeleteModelModal from "@/components/DeleteModelModal/DeleteModelModal";
import SiteCrawler from "@/components/SiteCrawler/SiteCrawler";
import ArrayAccordion from "@/components/ArrayAccordion/ArrayAccordion";
import MediaDoctor from "@/components/MediaDoctor/MediaDoctor";
import DiviModuleStats from "@/components/DiviModuleStats/DiviModuleStats";
import CompareMedia from "@/components/CompareMedia/CompareMedia";
import ImageSizes from "@/components/ImageSizes/ImageSizes";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'collection-items': CollectionItems,
    'delete-model-modal': DeleteModelModal,
    'site-crawler': SiteCrawler,
    'array-accordion': ArrayAccordion,
    'media-doctor': MediaDoctor,
    'divi-module-stats': DiviModuleStats,
    'compare-media': CompareMedia,
    'image-sizes': ImageSizes
};
