<template>
  <div class="filters" :class="{active: isMenuOpen}">
    <div class="filter search" :class="{'no-mobile-toggle':  !taxonomyFilters.length}">
      <InputText
          type="text"
          v-model="textSearch"
          placeholder="Search"
          size="normal"
          @keyup="didUserPressEnter($event)"
      />
      <span class="action" @click="setSearchInputFilterTerms()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 490.4 490.4">
                <g>
	              <path
                    d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796   s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z    M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z" />
                </g>
          </svg>
        </span>
    </div>
    <span  v-if="taxonomyFilters.length" class="filter toggle" @click="toggleFilterControls()">
        <svg viewBox="0 0 600 600">
          <g transform="matrix(0.95173205,0,0,0.95115787,13.901174,12.168794)" style="stroke-width:1.05103">
            <path style="stroke-linecap:round;-inkscape-stroke:none"
                  d="M 447.70881 -12.781343 A 42.041451 42.041451 0 0 0 405.66786 29.260344 L 405.66786 50.301721 L 27.434765 50.301721 A 42.041302 42.041302 0 0 0 -14.606185 92.341354 A 42.041302 42.041302 0 0 0 27.434765 134.38304 L 405.66786 134.38304 L 405.66786 155.44906 A 42.041451 42.041451 0 0 0 447.70881 197.49075 A 42.041451 42.041451 0 0 0 489.74976 155.44906 L 489.74976 134.38304 L 573.78036 134.38304 A 42.041302 42.041302 0 0 0 615.82336 92.341354 A 42.041302 42.041302 0 0 0 573.78036 50.301721 L 489.74976 50.301721 L 489.74976 29.260344 A 42.041451 42.041451 0 0 0 447.70881 -12.781343 z M 143.0012 197.48869 A 42.041451 42.041451 0 0 0 100.9582 239.53038 L 100.9582 260.5697 L 27.447078 260.5697 A 42.041302 42.041302 0 0 0 -14.593872 302.61139 A 42.041302 42.041302 0 0 0 27.447078 344.65308 L 100.9582 344.65308 L 100.9582 365.7191 A 42.041451 42.041451 0 0 0 143.0012 407.76078 A 42.041451 42.041451 0 0 0 185.04215 365.7191 L 185.04215 344.65308 L 573.79472 344.65308 A 42.041302 42.041302 0 0 0 615.83567 302.61139 A 42.041302 42.041302 0 0 0 573.79472 260.5697 L 185.04215 260.5697 L 185.04215 239.53038 A 42.041451 42.041451 0 0 0 143.0012 197.48869 z M 279.59427 407.76078 A 42.041451 42.041451 0 0 0 237.55332 449.80042 L 237.55332 470.83974 L 27.447078 470.83974 A 42.041302 42.041302 0 0 0 -14.593872 512.88143 A 42.041302 42.041302 0 0 0 27.447078 554.92106 L 237.55332 554.92106 L 237.55332 575.98913 A 42.041451 42.041451 0 0 0 279.59427 618.02877 A 42.041451 42.041451 0 0 0 321.63522 575.98913 L 321.63522 554.92106 L 573.79472 554.92106 A 42.041302 42.041302 0 0 0 615.83567 512.88143 A 42.041302 42.041302 0 0 0 573.79472 470.83974 L 321.63522 470.83974 L 321.63522 449.80042 A 42.041451 42.041451 0 0 0 279.59427 407.76078 z " />
          </g>
        </svg>
      </span>
    <div v-for="(taxonomy, index) in taxonomyFilters" class="filter taxonomy" :class="taxonomy.plural_name">
      <TaxonomyFilter :index="index" :taxonomy="taxonomy"></TaxonomyFilter>
    </div>
    <div v-if="taxonomyFilters.length" class="filter clear">
      <Button class="action" label="Reset" @click="clearAllFilters()" />
    </div>
  </div>
</template>

<script>
import TaxonomyFilter from "./TaxonomyFilter.vue"
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {InputText, Button, TaxonomyFilter},
  props:      {
    items:           {
      type:    Array,
      default: []
    },
    taxonomyFilters: {
      type:    Array,
      default: []
    },
    sidebarBreakpoint: {
      type: Number,
      default: 576
    }
  },

  data() {
    return {
      textSearch: "",
      isMenuOpen: null,
      windowWidth: null,
      prevWindowWidth: null,
    };
  },
  created() {
    this.windowWidth = window.innerWidth;
    this.isMenuOpen = this.windowWidth >= this.sidebarBreakpoint;
    window.addEventListener("resize", this.handleViewportResize);
  },
  mounted(){

  },
  methods: {
    handleViewportResize(event){
      this.prevWindowWidth = this.windowWidth;
      this.windowWidth = event.target.innerWidth;

      // Ensure filters are expanded when exceeding breakpoint
      if(this.windowWidth >= this.sidebarBreakpoint) {
        this.isMenuOpen = true;
      }

      // Ensure filters are collapsed when moving from exceeding breakpoint to below
      if((this.windowWidth < this.sidebarBreakpoint) && (this.prevWindowWidth >= this.sidebarBreakpoint)) {
        this.isMenuOpen = false;
      }

    },
    toggleFilterControls(){
      console.log('toggling filter controls');
      this.isMenuOpen = !this.isMenuOpen;
    },
    didUserPressEnter(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.setSearchInputFilterTerms();
      }
    },
    setSearchInputFilterTerms() {
      let terms = [];
      let input = this.textSearch;

      // Find occurrences of text wrapped in double quotes
      const regexp = /"(.*?)"/g;
      const multiWordTerms = input.match(regexp);
      if (multiWordTerms) {
        multiWordTerms.map((term) => {
          // remove the term, including its wrapping quotes from the input string
          input = input.replace(term, "");

          // remove the wrapping quotes from around the term
          term = term.replaceAll('"', '');

          terms.push(term.toLowerCase());
        });
      }

      // Split remaining terms in string into array and push
      input = input.trim();
      input = input.replace("  ", " ");

      // if anything is remaining, break out each word into a single term to search for
      if (input) {
        let singleWordTerms = input.split(" ");
        singleWordTerms.map((term) => {
          terms.push(term.toLowerCase());
        });
      }

      this.$parent.searchTerms = terms;

      // console.log("Search terms: ");
      // console.log(this.$parent.searchTerms);
      this.filterItems();
    },
    filterByTaxonomyTerms(source) {
      let taxFilterResults = [];

      // loop over each of the items to filter
      for (let itemIndex in source) {
        let item = source[itemIndex];
        item.includeInResults = true;
        // loop over the taxonomies in the taxonomyFilters
        for (let taxonomy in this.$parent.taxonomyFilterTerms) {
          // if there are terms to be filtered in the current taxonomyFilter
          if (this.$parent.taxonomyFilterTerms[taxonomy].length) {
            // loop over each of the terms
            for (let termIndex in this.$parent.taxonomyFilterTerms[taxonomy]) {
              // if the item does not contain the taxonomy term, exclude it from results.
              let term = this.$parent.taxonomyFilterTerms[taxonomy][termIndex];
              if (!item.taxonomies[taxonomy].terms.includes(term)) {
                item.includeInResults = false;
              }
            }
          }
        }
        if (item.includeInResults) {
          taxFilterResults.push(item);
        }
      }

      return taxFilterResults;
    },
    filterItemsBySearchInput(source) {
      // console.log('filtering via text input on the following items: ');
      // console.log(source);
      let searchFilterResults = [];

      if (this.$parent.searchTerms.length) {
        // loop over all available items
        for (let itemIndex in source) {
          let item = source[itemIndex];
          item.containsAllSearchTerms = true;

          for (let term in this.$parent.searchTerms) {
            let titleContainsTerm = false;
            let descContainsTerm = false;
            // Search the Title for the search term
            let title = item.title.toLowerCase();
            if (title.includes(this.$parent.searchTerms[term])) {
              titleContainsTerm = true;
            }

            // Search the Description for the search term
            let description = item.description;
            if (description.includes(this.$parent.searchTerms[term])) {
              descContainsTerm = true;
            }
            if (titleContainsTerm || descContainsTerm) {
              //something
            } else {
              item.containsAllSearchTerms = false;
            }
          }

          // if the item meets the criteria, push it to results
          if (item.containsAllSearchTerms) {
            searchFilterResults.push(item);
          }

        }
      } else {
        searchFilterResults = source;
      }

      return searchFilterResults;
    },
    filterItems() {
      let results = [];

      results = this.filterByTaxonomyTerms(this.items);
      results = this.filterItemsBySearchInput(results);

      this.$parent.filteredItems = results;

      // console.log('Filtered Results:')
      // console.log(this.$parent.filteredItems)
    },
    clearAllFilters() {
      this.$parent.searchTerms = [];
      this.textSearch = "";
      this.$parent.taxonomyFilterTerms = this.$parent.initializeTaxonomyFilterTerms();
      this.$parent.filteredItems = this.items;
    }
  }
}
</script>