<template>
  <section class="divi-module-stats">
    <TabView>
      <TabPanel header="Modules">
        <DataTable
            :value="moduleTableData"
            size="small"
            stripedRows
            paginator :rows="20">
          <Column field="label" header="Label" sortable></Column>
          <Column field="shortcode" header="Shortcode" sortable></Column>
          <Column field="count" header="Count" sortable></Column>
          <Column field="pages" header="Pages" sortable></Column>
        </DataTable>
      </TabPanel>
      <TabPanel header="Pages">
        <DataTable
            :value="pagesTableData"
            size="small"
            stripedRows
            paginator :rows="20">
          <Column field="label" header="Title" sortable></Column>
          <Column field="slug" header="Slug" sortable></Column>
          <Column field="postType" header="Post Type" sortable></Column>
          <Column field="totalModules" header="Module Count" sortable></Column>
        </DataTable>
      </TabPanel>
    </TabView>
  </section>
</template>

<script>


import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  components: {DataTable, Column, TabView, TabPanel},
  props:      {
    modules: {
      type:    Object,
      default: {}
    },
    diviPages: {
      type:    Object,
      default: {}
    },
  },

  data() {
    return {};
  },
  mounted() {
    // console.log('DiviModuleStats component mounted');
    // console.log(this.modules);
    // console.log(this.diviPages);
  },
  computed: {
    moduleTableData() {
      return Object.entries(this.modules).map((module) => {
        let _module = module[1];

        return {
          label: _module.label,
          shortcode: _module.shortcode,
          count: _module.total_count,
          pages: Object.keys(_module.locations).length,
        }
      });
    },
    pagesTableData() {
      return Object.entries(this.diviPages).map((module) => {
        let _module = module[1];

        return {
          label: _module.label,
          slug: _module.slug,
          postType: _module.post_type,
          totalModules: _module.total_modules,
        }
      });
    }
  },
  methods:  {}
}
</script>