<template>
  <div class="card">
    <div class="card__content">
      <div class="card__header">
        <h4 class="card__title" v-html="markWordsInSearch(item.title)"></h4>
      </div>
      <div class="divider"></div>
      <div class="card__body">
        <p v-html="markWordsInSearch(item.description)"></p>
        <CardTaxonomies
            :taxonomies="item.taxonomies"
            :cardSettings="cardSettings">
        </CardTaxonomies>
      </div>
    </div>
    <a class="card__footer" :href="item.url" target="_blank">
      <span>Access Now</span>
    </a>
  </div>
</template>

<script>
import CardTaxonomies from "./CardTaxonomies.vue";

export default {
  components: {CardTaxonomies},
  props: {
    item: {
      type:    Object,
      default: {}
    },
    cardSettings: {
      type:    Object,
      default: {}
    },
  },
  methods:  {
    hasWhiteSpace(string){
      return (/\s/).test(string);
    },
    markWordsInSearch(text) {
      // First look for grouped search terms and <mark> them, this must be done first,
      for(let i in this.$parent.$parent.searchTerms){
        if(this.hasWhiteSpace(this.$parent.$parent.searchTerms[i]) ){
          // console.log(this.$parent.searchTerms[i]);
          text = text.replace(this.$parent.$parent.searchTerms[i], "<mark>" + this.$parent.searchTerms[i] + "</mark>");
        }
      }

      // Then look for the single search terms and <mark> them
      let formattedText = []
      let words = text.split(' ');
      for (let i in words) {
        let word = words[i].toLowerCase().replace(/\.$/, "")
        if (this.$parent.$parent.searchTerms.includes(word)) {
          formattedText.push('<mark>' + words[i] + '</mark>');
        } else {
          formattedText.push(words[i]);
        }
      }
      formattedText = formattedText.join(' ')

      return formattedText;
    }
  }
}
</script>