<template>
  <section class="crawl-results">
    <div v-show="$parent.isCrawling || $parent.displayFinalResults || $parent.crawlPaused">
      <h2>Status</h2>
      <DataTable :value="[$parent.progressStats]">
        <Column field="elapsedTime" header="Time"></Column>
        <Column field="URLsToVisitCount" header="Queue"></Column>
        <Column field="totalRequests" header="Requests"></Column>
        <Column field="totalResponses" header="Responses"></Column>
      </DataTable>
    </div>

    <div v-show="$parent.isCrawling || $parent.displayFinalResults || $parent.crawlPaused">
      <h2>Responses</h2>
      <DataTable :value="[responsesTableData]">
        <Column field="status200" header="Status 200"></Column>
        <Column field="status300" header="Status 300"></Column>
        <Column field="status400" header="Status 400"></Column>
        <Column field="status500" header="Status 500"></Column>
      </DataTable>
    </div>

    <div v-show="$parent.isCrawling || $parent.crawlPaused">
      <h2>Request Threads</h2>
      <DataTable :value="treadsTableData">
        <Column field="thread" header="Thread"></Column>
        <Column field="hash" header="Hash"></Column>
        <Column field="url" header="URL"></Column>
      </DataTable>
    </div>

    <div v-show="$parent.displayFinalResults">
      <h2>Final Results</h2>
      <TabView>
        <TabPanel header="Status 200">
          <DataTable
              :value="$parent.results.status200"
              size="small"
              stripedRows
              paginator :rows="20">
            <Column field="url" header="URL" sortable></Column>
            <Column field="size" header="Size" sortable></Column>
            <Column field="imagesCount" header="Images" sortable></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Status 300">
          <DataTable
              :value="$parent.results.status300"
              size="small"
              stripedRows>
            <Column field="url" header="URL" sortable></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Status 400">
          <DataTable
              :value="$parent.results.status400"
              size="small"
              stripedRows>
            <Column field="url" header="URL" sortable></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Status 500">
          <DataTable
              :value="$parent.results.status500"
              size="small"
              stripedRows>
            <Column field="url" header="URL" sortable></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="All Images">
          <DataTable
              :value="$parent.results.images.data"
              size="small"
              stripedRows>
            <Column field="url" header="URL" sortable></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Gravity Forms">
          <DataTable
              :value="$parent.results.gravityForms.data"
              size="small"
              stripedRows>
            <Column field="url" header="URL" sortable></Column>
            <Column field="formId" header="Form ID" sortable></Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  </section>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default {
  components: {DataTable, Column, TabView, TabPanel},
  props:      {},

  data() {
    return {};
  },
  mounted() {
    // console.log('CrawlResults component mounted');
  },
  computed: {
    responsesTableData() {
      return {
        status200: this.$parent.results.status200 ? this.$parent.results.status200.length : 0,
        status300: this.$parent.results.status300 ? this.$parent.results.status300.length : 0,
        status400: this.$parent.results.status400 ? this.$parent.results.status400.length : 0,
        status500: this.$parent.results.status500 ? this.$parent.results.status500.length : 0,
      }
    },
    treadsTableData() {
      let rows = [];
      let count = 1;
      for (const [key, value] of Object.entries(this.$parent.threadProcesses)) {
        rows.push({
          thread: count,
          hash:   key,
          url:    value.url
        })
        count++;
      }
      return rows;
    }
  },
  methods:  {}
}
</script>