import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import components from '@/components/global';

let elements = document.querySelectorAll('.vue-instance');

for (let element of elements) {
    let pluginApp = createApp({
        mounted() {
            // console.log("lh-vue-components plugin JS enqueued & Vue instance mounted!");
        },

        setup() {

        }
    });

    for (const [name, component] of Object.entries(components)) {
        pluginApp.component(name, component);
    }

    pluginApp.use(PrimeVue);
    pluginApp.mount(element);

}