<template>
  <section v-if="showModal" class="delete-model-modal" :class="{active: showModal}">
    <div class="container">
      <header class="modal-header">
        <h2>Please confirm your action:</h2>
        <input type="submit" name="submit" value="Cancel" @click="showModal = !showModal">
      </header>
      <div class="option">
        <p>Are you sure you want to delete this {{ modelType }}?</p>
        <div class="input-group">
          <input type="radio" id="delete_model_no" v-model="deleteModel" value="0" checked="checked">
          <label for="delete_model_no">No</label><br>
          <input type="radio" id="delete_model_yes" v-model="deleteModel" value="1">
          <label for="delete_model_yes">Yes</label><br>
        </div>
      </div>

      <div class="option">
        <p>Would you like to delete all the data and tables associated with this {{ modelType }}?</p>
        <span class="soft-delete-message">
        Warning: Choosing to delete the associated data is a permanent action. Leaving this option as "No" will
        archive the data and remove its inclusion in any lists.
        </span>
        <div class="input-group">
          <input type="radio" id="delete_model_data_no" v-model="deleteModelData" value="0" checked="checked">
          <label for="delete_model_data_no">No</label><br>
          <input type="radio" id="delete_model_data_yes" v-model="deleteModelData" value="1">
          <label for="delete_model_data_yes">Yes</label><br>
        </div>
      </div>

      <div class="modal-footer">
        <input type="submit" name="submit" value="Confirm" @click="confirmDeletion">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    modelType: {
      type:    String,
      default: ''
    },
  },

  data() {
    return {
      href:            '',
      showModal:       false,
      deleteModel:     'no',
      deleteModelData: 'no',
    };
  },
  mounted() {
    // console.log('DeleteModelModal component mounted');

    let deleteTriggers = document.getElementsByClassName('delete-entry');
    for (let i = 0; i < deleteTriggers.length; i++) {
      console.log(this.showModal);
      deleteTriggers[i].addEventListener('click', this.handleDeleteConfirmation);
    }
  },
  computed: {},
  methods:  {
    handleDeleteConfirmation(e) {
      e.preventDefault();
      this.href = e.target.href
      this.showModal = true;
    },
    confirmDeletion() {
      if (this.deleteModel === '1') {
        if (this.deleteModelData === '1') {
          this.href = this.href + '&delete_data=1'
        }
        window.location.href = this.href;
      } else {
        this.showModal = false;
      }
    }
  }
}
</script>