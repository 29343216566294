<template>
  <section class="array-accordion">
    <h2>Array Accordion</h2>
    <ul>
      <li v-for="item in array">
        <span v-if="typeof item === 'object'">object</span>

        <div v-if="item.isArray">
          <p>array</p>
          <ul>
            <li v-for="_item in item">_item</li>
          </ul>
        </div>

      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    array: {
      type:    Array,
      default: []
    },
  },

  data() {
    return {
    };
  },
  mounted() {
    // console.log('ArrayAccordion component mounted');
  },
  computed: {},
  methods:  {

  }
}
</script>