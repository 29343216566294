<template>
  <section class="image-sizes">
    <Dropdown v-if="previousCrawls.length" v-model="previousCrawl" :options="previousCrawls" optionLabel="timestamp"
              optionValue="filename"
              placeholder="Load A Previous Crawl" @change="loadPreviousCrawlData" />
    <DataTable
        :value="imageSizesTableData"
        size="small"
        stripedRows
        paginator :rows="20">
      <Column field="label" header="label" sortable></Column>
      <Column field="width" header="width" sortable></Column>
      <Column field="height" header="Height" sortable></Column>
      <Column field="crop" header="Crop" sortable></Column>
    </DataTable>
  </section>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import Dropdown from "primevue/dropdown";

export default {
  components: {DataTable, Column, Dropdown},
  props:      {
    imageSizes:     {
      type:    Object,
      default: {}
    },
    previousCrawls: {
      type:    Array,
      default: []
    }
  },

  data() {
    return {
      previousCrawl: false,
      location:      "https://" + window.location.hostname,
    };
  },
  mounted() {
    console.log('ImageSizes component mounted');
    console.log(this.imageSizes);
    console.log(this.previousCrawls);
  },
  computed: {
    origin() {
      return !this.location.endsWith("/") ? this.location + "/" : this.location;
    },
    imageSizesTableData() {
      return Object.entries(this.imageSizes).map((imageSize) => {
        return {
          label:  imageSize[0],
          width:  imageSize[1].width,
          height: imageSize[1].height,
          crop:   imageSize[1].crop
        }
      });
    }
  },
  methods:  {
    loadPreviousCrawlData() {
      axios.get(this.origin + "/wp-content/uploads/site-crawler/" + this.previousCrawl)
          .then(response => {
            console.log(response.data);
          }).catch(function (error) {
        console.log(error.response);
      });
    }
  }
}
</script>